<template>
  <div ref="sidebar"
       class="sidebar"
       :class="{ 'hide-in-small-screens': $route.name !== 'Chat', '--shadow': showShadow }">
    <img src="@/assets/logo.svg" alt="SWORD Health logo" class="sword-logo">

    <div class="sidebar-bottom-annotations">
      <profile-picture class="profile-avatar"
                       :picture="userPicture"
                       :gender="userGender" />
      <h5 class="profile-name -t_medium">
        {{ userFullName }}
      </h5>
      <p class="chat-version-annotation t5 fc-blue-5 center-text -mt_1 -mb_0">
        {{ appVersion }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import ProfilePicture from '@ui-kit/components/pictures/ProfilePicture.vue';

let scrollListener;
let chatListContainer;

export default {
  name: 'Sidebar',
  components: { ProfilePicture },
  data() {
    return { scrolled: false };
  },
  computed: {
    ...mapGetters({ appVersion: 'appVersion', user: 'user/getUserDetails' }),
    userFullName() {
      return this.user ? `${this.user.firstName} ${this.user.lastName}` : '';
    },
    userPicture() {
      return this.user && this.user.picture;
    },
    userGender() {
      return this.user && this.user.gender;
    },
    showShadow: {
      get() {
        const sidebarHeight = this.$refs.sidebar && this.$refs.sidebar.offsetHeight;
        return sidebarHeight < this.scrolled;
      },
    },
  },
  mounted() {
    const observer = new MutationObserver(() => {
      chatListContainer = document.querySelector('.chats-list-container');
      if (chatListContainer) {
        scrollListener = chatListContainer.addEventListener('scroll', () => {
          this.updateScrolled(chatListContainer.scrollTop);
        });
        observer.disconnect();
      }
    });

    observer.observe(document, {
      childList: true,
      subtree: true,
    });
  },
  destroyed() {
    if (chatListContainer) {
      chatListContainer.removeEventListener('scroll', scrollListener);
    }
  },
  methods: {
    updateScrolled(newValue) {
      this.scrolled = newValue;
    },
  },
};
</script>

<style scoped lang="scss">
.sidebar {
  $sidebar-width: auto;
  display: flex;
  flex: 0 0 $sidebar-width;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.5em 1em;
  background-color: getColor($greys, 7);
  overflow: hidden;
  transition: box-shadow 0.25s;

  &.--shadow {
    @media screen and (max-width: $max-tablet-size) {
      box-shadow: 0 -2px 16px 0 $shadow-darker;
    }
  }

  @media screen and (max-width: $max-tablet-size) {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 1em;
    background-color: transparent;
  }
}

.sword-logo {
  position: relative;
  width: 2em;
}

.profile-avatar {
  position: relative;
  width: 2em;
}

.profile-name {
  display: none;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 1em;
}

.chat-version-annotation {
  margin-top: 0;
  margin-bottom: 0;
}

@media screen and (max-width: $max-tablet-size) {
  .sidebar-bottom-annotations {
    width: 100%;
    display: flex;
    align-items: center;
  }
  .profile-avatar {
    flex-grow: 1;
    max-width: 2.5em;
    max-height: 2.5em;
  }
  .profile-name {
    display: block;
  }
  .chat-version-annotation {
    margin-left: auto;
  }
  .sword-logo {
    display: none;
  }
}
</style>
