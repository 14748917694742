<template>
  <div class="main-app-view">
    <sidebar />

    <div class="main-view">
      <router-view />
    </div>
  </div>
</template>

<script>
import Sidebar from '@/components/Sidebar.vue';

export default {
  name: 'Main',
  components: { Sidebar },
};
</script>

<style lang="scss" scoped>
.main-app-view {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;

  @media screen and (max-width: $max-tablet-size) {
    flex-direction: column;
    overflow-y: hidden;
  }
}

.main-view {
  flex-grow: 1;
  overflow: hidden;

  @media screen and (max-width: $max-tablet-size) {
    flex: 1;
    max-height: 100%;
    overflow-y: hidden;
  }
}
</style>
